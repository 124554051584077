<!-- <app-header></app-header> -->
<router-outlet></router-outlet>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>


<div id="attractions" class="nearby-places-section">
  <h2 class="section-heading">Nearby Places</h2>
  <div class="unique-cards-container d-none d-md-flex">
    <div class="card unique-card">
      <img src="assets/images/Sula Vineyards.jpg" class="card-img-top" alt="Water Park">
      <div class="card-body">
        <p class="card-text">Sula Vineyards</p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Pandav Leni.jpg" class="card-img-top" alt="Pandav Leni">
      <div class="card-body">
        <p class="card-text">Pandav Leni</p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Kalaram Temple.jpg" class="card-img-top" alt="Kalaram Temple">
      <div class="card-body">
        <p class="card-text">Kalaram Temple</p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Soma Vine Village.jpg" class="card-img-top" alt="Soma Vine Village">
      <div class="card-body">
        <p class="card-text">Soma Vine Village</p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Gangapur Dam.jpg" class="card-img-top" alt="Gangapur Dam">
      <div class="card-body">
        <p class="card-text">Gangapur Dam</p>
      </div>
    </div>
  </div>
</div>




<div class="unique-carousel-wrapper d-block d-md-none">
  <h2 class="section-heading">Nearby Places</h2>
  <div class="unique-carousel-card shadow-lg border-0 rounded overflow-hidden">
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner unique-carousel-inner">
        <!-- Loop through the items using Angular's *ngFor -->
        <div
          *ngFor="let item of carouselItems; let i = index"
          class="carousel-item"
          [ngClass]="{ 'active': i === 0 }"
        >
          <div class="unique-carousel-item-content text-center p-3">
            <div class="unique-carousel-item-card">
              <img [src]="item.imgSrc" [alt]="item.title" class="img-fluid circular-image">
              <h2 class="mt-3">{{ item.title }}</h2>
              <p>{{ item.age }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Carousel Controls -->
      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>









<div class="container my-5">
  <div class="row" id="testimonials">
    <!-- First Column -->
    <div class="col-md-4 mb-4 d-flex flex-column justify-content-center align-items-start">
      <h2 class="headline font-weight-bold mb-3">Real Stays, Real Talk</h2>
      <p class="intro-text mb-4 text-muted">Here's what our guests are saying...</p>
      <a href="https://www.google.com/travel/search?q=Swiss%20Trafalgar&g2lb=4814050%2C4874190%2C4893075%2C4965990%2C4969803%2C72277293%2C72302247%2C72317059%2C72406588%2C72414906%2C72421566%2C72471280%2C72472051%2C72473841%2C72481459%2C72485658%2C72499704%2C72602734%2C72614662%2C72616120%2C72619927%2C72628719%2C72647020%2C72648289%2C72658035%2C72671093%2C72686036%2C72729609%2C72749231%2C72757849%2C72760082%2C72766791%2C72783135%2C72789811&hl=en-IN&gl=in&cs=1&ssta=1&ts=CAEaRwopEicyJTB4M2JkZGViYzg4N2IzNTBmYjoweDdlNTZhMjIxYjQyNTBlOWMSGhIUCgcI6Q8QARgWEgcI6Q8QARgXGAEyAhAA&qs=CAEyE0Nnb0luSjJVb1p2RXFLdC1FQUU4AkIJCZwOJbQholZ-QgkJnA4ltCGiVn4&ap=ugEHcmV2aWV3cw&ictx=111&ved=0CAAQ5JsGahcKEwi49JvBs6iJAxUAAAAAHQAAAAAQBw" class="btn btn-primary btn-lg writereview">
      <div style="padding-left: 10px;padding-right: 10px;">
        Write a Review
      </div>
    </a>
    </div>

    <!-- Second Column (Carousel for Desktop) -->
    <div class="col-md-8 mb-4  d-md-block">
      <div class="card shadow-sm border-0 h-100">
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner style_carasol" style="display: block;" >
                        <div
              class="carousel-item" data-interval="10000"
              *ngFor="let item of googleReviews; let i = index"
              [ngClass]="{ 'active': i === 0 }"
            >
              <div class="row">
                <div class="col-md-12 d-flex align-items-center">
                  <div class="testimonial p-3">


                       <div style="text-align: center;">
                        <div>
                          <img
                          src="assets/images/test.png"
                          alt="{{ item.reviewerName }}"
                          class="rounded-circle img-fluid shadow-lg mb-3"
                          style="width: 80px; height: 80px;"
                        />
                        <br/>
                        </div>

                       <div>
                        <h5 class="font-weight-bold mb-1">{{ item.reviewerName }}</h5>
                       </div>
                      <div>
                        <p class="small text-muted mb-2 review-text">{{ item.reviewText }}</p>
                      </div>

                        <div class="star-ratings text-warning">
                          {{ '★'.repeat(item.rating) }}
                        </div>
                       </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Carousel Controls Positioned Outside -->
      <div class="carousel-controls">
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>




  </div>
</div>






  <div id="location" style="margin-bottom: 2px;">
  <div style="position: relative; width: 100%; max-width: 100%; height: 600px;">
    <!-- The iframe -->
    <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d120006.1994982123!2d73.67918790414019!3d19.958353189043713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bddebc887b350fb%3A0x7e56a221b4250e9c!2s910%2C%20Mumbai%20-%20Agra%20Rd%2C%20Dnyaneshwar%20Nagar%2C%20Pathardi%20Phata%2C%20Nashik%2C%20Maharashtra%20422009!3m2!1d19.9583722!2d73.7615896!5e0!3m2!1sen!2sin!4v1729820586391!5m2!1sen!2sin" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <div   class="card" style="position: absolute; top: 50px; text-align: center; left: 50px; background-color: white; border-bottom-left-radius: 61px;box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-top-right-radius: 59px;
    padding: 50px;">
        <h3>Make Unforgettable Memories</h3>
        <p>We're here to assist you with any questions, requests, or bookings.<br> Please don't hesitate to reach out to us.</p>
        <button class="book-btn" (click)="navigate()">Book Your Stay</button>
        <p class="contact-footer">
          <i class="fa fa-phone"></i> {{property?.mobile}}
        </p>
        <p class="contact-footer">
          <i class="fa fa-envelope"></i> {{property?.email}}
        </p>

      </div>

  </div>
</div>
<!-- Dialogflow Messenger Integration -->

<df-messenger project-id="impactful-name-306810" agent-id="8bb6c52c-8667-4a24-a789-4cd78b154c96" intent="WEBSITE" storage-option="none" language-code="en" max-query-length="-1"   >
  <df-messenger-chat-bubble  id="chatbot" chat-subtitle=""></df-messenger-chat-bubble>
</df-messenger>
<a [href]="getWhatsappShareUrl()" class="float" target="_blank" >
  <i class="fa fa-whatsapp my-float"></i>
</a>

<app-footer></app-footer>

